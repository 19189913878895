import React from 'react';
import styled from 'styled-components';

import {
  contentFullWidth,
  contentHorizontalPadding,
  boldFontFamily,
  mobileSize,
} from '../components/base_styles';
import SayHello from '../components/home/SayHello';
import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';
import BeckyImg from '../images/becky.jpg';

const IntroWrapper = styled.div`
  background: #5FDECF;
  padding: 160px 0;

  @media (max-width: ${mobileSize}) {
    padding: 48px 0;
  }
`;

const Intro = styled.div`
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 0 ${contentHorizontalPadding};
`;

const IntroHeading = styled.h1`
  color: white;
  font-family: ${boldFontFamily};
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 44px;

  @media (max-width: ${mobileSize}) {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 32px;
  }
`;

const IntroText = styled.p`
  color: white;
  font-family: ${boldFontFamily};
  font-size: 22px;
  line-height: 32px;
  max-width: 836px;

  @media (max-width: ${mobileSize}) {
    font-size: 20px;
  }
`;

const About = styled.div`
  background: white;
  box-shadow: 32px 32px 0px #FCE8E4;
  display: flex;
  margin: 0 auto;
  margin-top: -32px;
  max-width: 826px;
  padding: 96px 64px;

  @media (max-width: ${mobileSize}) {
    box-shadow: none;
    flex-direction: column;
    margin-top: 0;
    padding: 48px ${contentHorizontalPadding};
  }
`;

const AboutImgWrapper = styled.div`
  margin:0 40px 18px 0;
  min-width: 250px;

  @media (max-width: ${mobileSize}) {
    margin-right: 0;
    width: 100%;
  }
`;

const AboutImg = styled.img`
  height: auto;
  min-width: 250px;
  width: 100%;
`;

const AboutTextWrapper = styled.div`
  h3 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }
`;

const Collaborate = styled.div`
  display: flex;
  margin: 168px auto 136px auto;
  max-width: ${contentFullWidth};
  padding: 0 ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    border-top: 1px solid #BCBCBC;
    flex-direction: column;
    margin: 0 ${contentHorizontalPadding};
    padding: 40px 0;
  }
`;

const CollaborateHeading = styled.h1`
  margin: 0 64px 18px 0;
  min-width: 290px;

  @media (max-width: ${mobileSize}) {
    margin: 0 0 18px 0;
    min-width: 0;
  }
`;

const CollaborateTextWrapper = styled.div`
  p {
    margin-bottom: 16px;
  }
`;

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <IntroWrapper>
      <Intro>
        <IntroHeading>Milk + Cookies</IntroHeading>
        <IntroText>
          I pair insightful thinking with clever design. I believe it is this combination which
          makes the best work for the people making our world better.
        </IntroText>
      </Intro>
    </IntroWrapper>
    <About>
      <AboutImgWrapper>
        <AboutImg src={BeckyImg} alt="Becky" />
      </AboutImgWrapper>
      <AboutTextWrapper>
        <h3>About me</h3>
        <p>
          I’m a born and bred Brighton Designer with over 10 years experience in working with
          purposeful business’,charities and organisations. I design brands and communications
          that place people at the heart of everything they say and do. My interest for this
          type of work started at university where I found the challenge in motivating kindness
          and action most exciting.
        </p>
        <p>
          My past projects have varied in scale and reach, but no matter how big or small I always
          bring insightful thinking, creativity and a resourceful approach. At the start of any
          project my curiosity and focus helps me immerse myself into your world and your
          audience’s too. My process of conceptual thinking and exploration (leaving no stones
          unturned) helps lead to surprising outcomes and meaningful design.
        </p>
      </AboutTextWrapper>
    </About>

    <Collaborate>
      <CollaborateHeading>Let’s collaborate</CollaborateHeading>
      <CollaborateTextWrapper>
        <p>
          For larger projects I collaborate with other creatives to deliver brands, campaigns
          and websites that have all the imagination and impact of an agency’s work but without
          the overhead costs.
        </p>
        <p>
          I’m always looking for ways to combine skills on a project, if you work in the same
          industry and interested in the possibility of collaborating then please get it touch.
        </p>
      </CollaborateTextWrapper>
    </Collaborate>

    <SayHello title="What’s your challenge?">
      How do we gain recognition, amplify your voice, grow your tribe, raise funds or help
      spark change? I create brands, campaigns and visual content that help solve these
      kinds of challenges. If you have a challenge to share then please get in touch.
    </SayHello>
  </Layout>
);

export default AboutPage;
