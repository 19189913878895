import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { contentFullWidth, contentHorizontalPadding, mobileSize } from '../base_styles';
import Button from '../Button';

const SayHelloWrapper = styled.div`
  background: #403E45;
`;

const SayHelloDiv = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 136px ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    flex-direction: column;
    padding: 48px ${contentHorizontalPadding};
  }
`;

const SayHelloHeader = styled.h1`
  color: white;
  margin:0 64px 18px 0;
  min-width: 290px;

  @media (max-width: ${mobileSize}) {
    margin: 0 0 18px 0;
    min-width: 0;
  }
`;

const SayHelloTextWrapper = styled.div`
  max-width: 540px;

  p {
    color: white;
    margin-bottom: 32px;
  }
`;

const SayHello = ({ title, children }) => (
  <SayHelloWrapper>
    <SayHelloDiv>
      <SayHelloHeader>{ title }</SayHelloHeader>
      <SayHelloTextWrapper>
        <p>{ children }</p>
        <Button link="mailto:hello@beckyalford.co.uk" text="Email me" />
      </SayHelloTextWrapper>
    </SayHelloDiv>
  </SayHelloWrapper>
);

SayHello.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default SayHello;
